import React from "react";
import "./Navbar.css";

export default function Navbar() {
  return (
    <div className="outline sticky-top">
      <nav className="Navbar navbar sticky-top navbar-collapse-lg" id="navbar">
        <div className="container-fluid">
          <div className="mx-auto">
            <button type="button" className="navbar-toggler toggle-button active" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-roledescription="navigation" aria-expanded="false">
              <i className="toggle-button bi bi-list navbar-toggler-icon"></i>
            </button>

            <div className="collapse navbar-expand justify-content-center" id="navbarCollapse" data-bs-toggle="collapse" data-bs-target="#navbarCollapse.hide">
              <a href="#app" className="home-button">
                <div className="home-button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse.hide"></div><p className="home-text">Home</p>
              </a>
              <a href="#about" className="nav-item nav-link"><i className="bi bi-file-earmark-person-fill pt-3"></i><p>About</p></a>
              <a href="#work" className="nav-item nav-link"><i className="bi bi-code-slash pt-3"></i><p>Work</p></a>
              <a href="#contact" className="nav-item nav-link pb-3"><i className=" bi bi-envelope pt-3"></i><p>Contact</p></a>
            </div>
          </div>
        </div >
      </nav >
    </div >
  );
}
