import React from "react";
import "./Skill.css";

export default function Skill(props) {
  return (
    <div className="Skill row" id="skill">
      <div>
        {props.skill.map((skill, index) => (
          <p className="col-3" key={index}>
            {skill}
          </p>
        ))}
      </div>

    </div>
  );
}