import React from "react";
import "./Contact.css";

export default function Contact() {

  var url = window.location.href;
  function emailOption() {
    if (url.search('sent=true') >= 0) {
      return (
        <div className="sent-note">
          <h4 className="message-sent">Message Sent! Thanks, I will get back to you shortly</h4>
          <img src="public-media/sent_icon.png" alt="message sent" />
        </div>
      );

    } else {

      return (
        <form action="https://formsubmit.co/deborahadadewa@gmail.com" method="POST" className="contact-form" autoComplete="off">
          <div className="form-row">
            <div className="col">
              <input type="text" name="name" placeholder="Full Name" className="form-control" required />
            </div>
            <div className="col">
              <input type="email" name="email" placeholder="Email" className="form-control" required />
            </div>

            <input type="hidden" name="_subject" value="Email from Portfolio!" />
            <input type="hidden" name="_next" value={`${url}?sent=true#contact`} />
            <input type="text" name="_honey" style={{ display: "none" }} />
            <input type="hidden" name="_captcha" value="false" />
          </div>

          <div className="form-row">
            <div className="col">
              <textarea name="message" id="" cols="40" rows="10" placeholder="Your message" className="form-control" required></textarea>
            </div>
            <div className="col">
              <button type="submit" className="btn btn-primary submit-btn"><h5>Send</h5></button>
            </div>
          </div>
        </form>
      );
    }
  }

  return (

    <section className="Contact" id="contact">
      <h2>Contact</h2>
      {emailOption()}
      <div className="contact-options justify-content-start">
        <a href="#contact" className="options"><i className="bi bi-github" /></a>
        <a href="https://www.linkedin.com/in/deborah-botchwey" className="options" target="_blank" rel="noreferrer"><i className="bi bi-linkedin" /></a>

      </div>
    </section>);
}