import React from "react";
import "./WebProject.css";

export default function WebProject(props) {
  return (
    <div className="web-project outline" id="web-project">

      <img src={props.image} alt={props.name} className="project-image" />

      <div className="overlay overlay-blur outline">
        <div className="title">{props.name}</div>
        <p className="project-description">{props.description}</p>
        <a className="link-btn" href={props.link} target="_blank" rel="noreferrer"> <p>Go to project</p></a>
      </div>

    </div>
  );
}
