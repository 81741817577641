import React from "react";
import "./Work.css";
import WebProject from "./WebProject";
import DictionaryImage from "./media/dictionary.png";
import WeatherReact from "./media/cinnamonweather.jpg";
import Kloudy from "./media/kloudy.jpg";



export default function Work() {
  return (
    <section className="Work text-content-lavendar" id="work">
      <h2>Work</h2>

      <h5>Web Projects:</h5>

      <div className="web container row">
        <div className="col-sm-4">
          <WebProject name="Image Dictionary" image={DictionaryImage} link="https://imagedictionary.netlify.app" description="A dictionary web app that shows images of what you are looking for in addition to the definition." />
        </div>
        <div className="col-sm-4">
          <WebProject name="Cinnamon Weather" image={WeatherReact} link="https://cinnamonweather.netlify.app" description="A cinnamon color scheme weather app. This is a remake of the Kloudy app to compare the benefits of using React vs pure JavaScript " />
        </div>
        <div className="col-sm-4">
          <WebProject name="Kloudy" image={Kloudy} link="https://kloudy.netlify.app" description="A weather app using JavaScript" />
        </div>

      </div>

      <h5 className="logofolio-title">Logofolio:</h5>
      <div className="logofolio container row px-0">
        <div className="col-sm-4 logo-e padding-0">
          <div className="outline-purple logo-container">
            <img src="public-media/RoseCookiesDesign.jpg" alt="Rose Cookies Design Logo" />
            <div className="overlay-logo"></div>
          </div>
        </div>
        <div className="col-sm-4 logo-e padding-0">
          <div className="outline-purple logo-container">
            <img src="public-media/AwosKitchenWhitebg.jpg" alt="Awo's Kitchen Logo" />
            <div className="overlay-logo"></div>
          </div>
        </div>
        <div className="col-sm-4 logo-e padding-0">
          <div className="outline-purple logo-container">
            <img src="public-media/mylogo.jpg" alt="Deborah Adadewa Logo" />
            <div className="overlay-logo"></div>
          </div>
        </div>
      </div>
    </section>
  );
}